import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

/*
  Filename:    Register.js
  Description: This page is the registration page for volunteers.
*/

const Register = () => {
  useEffect(() => {
    // Code to run only on first page load
    window.scrollTo(0, 0); // scroll to top of page
  }, []);

  //const url = process.env.REACT_APP_API_URL + '/api/register';
  const url = "https://bridginghope.life/api/client"; //uncomment for local testing
  const navigate = useNavigate();

  const [clientInfo, setClientInfo] = useState({
    clientID: "",
    firstName: "",
    middleName: "",
    lastName: "",
    DOB: "",
    photo: "",
  });

  const [phoneInfo, setPhoneInfo] = useState({
    phoneID: "",
    countryCode: "",
    areaCode: "",
    telPrefix: "",
    lineNum: "",
    isActive: true,
    isSafe: true,
    phoneType: "",
  });

  const [addressInfo, setAddressInfo] = useState({
    addressID: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    addressType: "",
  });

  const [userInfo, setUserInfo] = useState({
    userID: "",
    email: "",
    userName: "",
    password: "",
  });

  const [confirmPassword, setConfirmPassword] = useState({
    confirmPassword: "",
  });
  const [errors, setErrors] = useState([]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const validateInputs = () => {
    const nameReg = /^[A-Za-z'-]+$/;
    const opNameReg = /^[A-Za-z'-]*$/;
    const dateReg = /\b(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\b/;

    const add1Reg = /^[A-Za-z0-9\s,.-]+$/;
    const add2Reg = /^[A-Za-z0-9\s,.-]*$/;
    const cityReg = /^[A-Za-z\s'-]+$/;
    const zipReg = /^\d{5}(-\d{4})?$/;

    const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const userNameReg = /^[a-zA-Z0-9._-]{3,15}$/;
    const passReg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    const stringReg = /^[A-Za-z0-9 .,!?'-]+$/;
    const numReg = /^\d+$/;
    const boolReg = /^(true|false)$/;

    let newErrors = [];

    // Client information validation
    if (!nameReg.test(clientInfo.firstName)) {
      newErrors.push("First name must only contain letters, (') or (-)");
    }
    if (!opNameReg.test(clientInfo.middleName)) {
      console.log(clientInfo.middleName);
      newErrors.push("Middle name must only contain letters, (') or (-)");
    }
    if (!nameReg.test(clientInfo.lastName)) {
      newErrors.push("Last name must only contain letters, (') or (-)");
    }
    if (!dateReg.test(clientInfo.DOB)) {
      console.log(clientInfo.DOB);
      newErrors.push("You must enter a valid date");
    }

    // Address information validation
    if (!add1Reg.test(addressInfo.addressLine1)) {
      newErrors.push(
        "Address Line 1 must only contain letters, numbers, whitespace, (,), (.) or (-)"
      );
    }
    if (!add2Reg.test(addressInfo.addressLine2)) {
      newErrors.push(
        "Address Line 2 must only contain letters, numbers, whitespace, (,), (.) or (-)"
      );
    }
    if (!cityReg.test(addressInfo.city)) {
      newErrors.push("City must only contain letters, whitespace, (') or (-)");
    }
    if (!zipReg.test(addressInfo.zip)) {
      newErrors.push(
        "Zip code must be a number and must be in the format 11111 or 11111-1111"
      );
    }
    if (addressInfo.addressType === "") {
      newErrors.push("Please select a address type");
    }

    // Phone number validation
    if (!numReg.test(Number(phoneInfo.countryCode))) {
      newErrors.push("Country code must be a number");
    }
    if (!numReg.test(Number(phoneInfo.areaCode))) {
      newErrors.push("Area code must be a number");
    }
    if (!numReg.test(Number(phoneInfo.telPrefix))) {
      newErrors.push("Telephone prefix must be a number");
    }
    if (!numReg.test(Number(phoneInfo.lineNum))) {
      newErrors.push("Line number must be a number");
    }
    if (!boolReg.test(phoneInfo.isActive)) {
      newErrors.push("The phone number must be active or not");
    }
    if (!boolReg.test(phoneInfo.isSafe)) {
      newErrors.push("The phone number must be safe or not");
    }
    if (phoneInfo.phoneType === "") {
      newErrors.push("Please select a phone type");
    }

    // User information validation
    if (!emailReg.test(userInfo.email)) {
      newErrors.push("Please enter a valid email address");
    }
    if (!userNameReg.test(userInfo.userName)) {
      newErrors.push(
        "Username must be between 3 and 15 characters and only include alphanumeric, (.), (_) or (-)"
      );
    }
    if (!passReg.test(userInfo.password)) {
      newErrors.push(
        `Password must be at least 8 characters and must include at least a capital and lowercase letter, a number, and a special character consisting of !@#$%^&*(),.?":{}|<></>`
      );
    } else {
      if (userInfo.password !== confirmPassword.confirmPassword) {
        newErrors.push("Password and Confirm Password must be the same");
      }
    }

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const handleChange = (event) => {
    const { dataset, name, value } = event.target;

    if (dataset.type === "client") {
      setClientInfo({
        ...clientInfo,
        [name]: value,
      });
    }
    if (dataset.type === "phone") {
      if (name === "phoneNumber") {
        let areaCode = value.slice(0, 3);
        let telPrefix = value.slice(3, 6);
        let lineNum = value.slice(6, 10);
        setPhoneInfo({
          ...phoneInfo,
          areaCode: areaCode,
          telPrefix: telPrefix,
          lineNum: lineNum,
        });
      } else {
        setPhoneInfo({
          ...phoneInfo,
          [name]: value,
        });
      }
    }
    if (dataset.type === "address") {
      setAddressInfo({
        ...addressInfo,
        [name]: value,
      });
    }
    if (dataset.type === "user") {
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    }
    if (dataset.type === "confirmPassword") {
      setConfirmPassword({
        ...confirmPassword,
        [name]: value,
      });
    }

    console.log(dataset.type, name, value);
    console.log(clientInfo, phoneInfo, addressInfo, userInfo);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInputs();

    if (isValid) {
      let params = {
        client: clientInfo,
        phone: phoneInfo,
        address: addressInfo,
        user: userInfo,
      };

      try {
        await axios.post(url, params);
        setShowSuccessModal(true); // Show success modal
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Error registering user";
        setErrors({ message: errorMessage });
        alert("Registration Failed");
      }
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate("/dashboard"); // Redirect to dashboard
  };

  return (
    <React.Fragment>
      <div className="col-12 d-flex justify-content-center align-items-center min-vh-100">
        <div className="card col-sm-11 col-md-8 col-lg-6">
          <div className="card-body">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <img
                src="images/BridgingHopeWord.svg"
                className="img-fluid col-6"
                alt="logo"
              />
            </div>
            <form className="mt-5" onSubmit={handleSubmit}>
              {/* these columns could be adjusted for different screen sizes */}
              {/* first and last name */}
              <h5 className="mt-2">Client Information</h5>
              <label htmlFor="txtFirstName" className="form-label mt-2">
                First Name
              </label>
              <input
                id="txtFirstName"
                type="text"
                placeholder="John"
                className="form-control"
                data-type="client"
                name="firstName"
                value={clientInfo.firstName}
                onChange={handleChange}
                required
              />
              <label htmlFor="txtMiddleName" className="form-label mt-2">
                Middle Name
              </label>
              <input
                id="txtMiddleName"
                type="text"
                placeholder="Allan"
                className="form-control"
                data-type="client"
                name="middleName"
                value={clientInfo.middleName}
                onChange={handleChange}
              />
              <label htmlFor="txtLastName" className="form-label mt-2">
                Last Name
              </label>
              <input
                id="txtLastName"
                type="text"
                placeholder="Doe"
                className="form-control"
                data-type="client"
                name="lastName"
                value={clientInfo.lastName}
                onChange={handleChange}
                required
              />
              <label htmlFor="dateDOB" className="form-label mt-2">
                Date of Birth
              </label>
              <input
                id="dateDOB"
                type="date"
                className="form-control"
                data-type="client"
                name="DOB"
                value={clientInfo.DOB}
                onChange={handleChange}
                required
              />
              <label htmlFor="filProfilePic" className="form-label mt-2">
                Profile Picture (Optional)
              </label>
              <input
                id="filProfilePic"
                type="file"
                className="form-control"
                data-type="client"
                name="photo"
                value={clientInfo.photo}
                onChange={handleChange}
              />
              {/* phone info */}
              <h5 className="mt-2">Contact Information</h5>
              <label htmlFor="numCountryCode" className="form-label mt-2">
                Country Code
              </label>
              <select
                id="numCountryCode"
                type="number"
                className="form-select"
                data-type="phone"
                name="countryCode"
                value={phoneInfo.areaCode}
                onChange={handleChange}
                required
              >
                <option value="1">+1</option>
              </select>

              <label htmlFor="telPhoneNumber" className="form-label mt-2">
                Phone Number
              </label>
              <input
                id="telPhoneNumber"
                type="tel"
                className="form-control"
                data-type="phone"
                name="phoneNumber"
                value={
                  phoneInfo.areaCode + phoneInfo.telPrefix + phoneInfo.lineNum
                }
                onChange={handleChange}
                required
              />

              <label htmlFor="txtPhoneType" className="form-label mt-2">
                Type of Phone Number
              </label>
              <select
                id="txtPhoneType"
                type="text"
                className="form-control"
                data-type="phone"
                name="phoneType"
                value={phoneInfo.phoneType}
                onChange={handleChange}
                required
              >
                <option value="Personal">Personal</option>
                <option value="Work">Work</option>
                <option value="Business">Business</option>
              </select>

              {/* address Info */}
              <h5 className="mt-2">Address Information</h5>
              <label htmlFor="txtAddressLine1" className="form-label mt-2">
                Address Line 1
              </label>
              <input
                id="txtAddressLine1"
                type="text"
                className="form-control"
                data-type="address"
                name="addressLine1"
                value={addressInfo.addressLine1}
                onChange={handleChange}
                required
              />
              <label htmlFor="txtAddressLine2" className="form-label mt-2">
                Address Line 2 (Optional)
              </label>
              <input
                id="txtAddressLine2"
                type="text"
                className="form-control"
                data-type="address"
                name="addressLine2"
                value={addressInfo.addressLine2}
                onChange={handleChange}
              />
              <label htmlFor="txtCity" className="form-label mt-2">
                City
              </label>
              <input
                id="txtCity"
                type="text"
                className="form-control"
                data-type="address"
                name="city"
                value={addressInfo.city}
                onChange={handleChange}
                required
              />
              <label htmlFor="txtState" className="form-label mt-2">
                State
              </label>
              <input
                id="txtState"
                type="text"
                className="form-control"
                data-type="address"
                name="state"
                value={addressInfo.state}
                onChange={handleChange}
                required
              />
              <label htmlFor="numZip" className="form-label mt-2">
                Zip Code
              </label>
              <input
                id="numZip"
                type="number"
                className="form-control"
                data-type="address"
                name="zip"
                value={addressInfo.zip}
                onChange={handleChange}
                required
              />
              <label htmlFor="txtAddressType" className="form-label mt-2">
                Address Type
              </label>
              <select
                id="txtAddressType"
                type="text"
                className="form-control"
                data-type="address"
                name="addressType"
                value={addressInfo.addressType}
                onChange={handleChange}
                required
              >
                <option value="Home">Home</option>
                <option value="Shelter">Shelter</option>
                <option value="Business">Business</option>
              </select>

              {/* username, email, password, and confirm password */}
              <h5 className="mt-2">User Information</h5>
              <label htmlFor="txtEmail" className="form-label mt-2">
                Email
              </label>
              <input
                id="txtEmail"
                type="email"
                placeholder="jdoe@email.com"
                className="form-control"
                autoComplete="on"
                data-type="user"
                name="email"
                value={userInfo.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="txtUsername" className="form-label mt-2">
                Username
              </label>
              <input
                id="txtUsername"
                type="text"
                placeholder="johndoe"
                className="form-control"
                autoComplete="on"
                data-type="user"
                name="userName"
                value={userInfo.userName}
                onChange={handleChange}
                required
              />
              <label htmlFor="txtPassword" className="form-label mt-2">
                Password
              </label>
              <input
                id="txtPassword"
                type="password"
                className="form-control"
                autoComplete="on"
                data-type="user"
                name="password"
                value={userInfo.password}
                onChange={handleChange}
                required
              />
              <label htmlFor="txtConfirmPassword" className="form-label mt-2">
                Confirm Password
              </label>
              <input
                id="txtConfirmPassword"
                type="password"
                className="form-control"
                data-type="confirmPassword"
                name="confirmPassword"
                value={confirmPassword.confirmPassword}
                onChange={handleChange}
                required
              />
              {errors.length > 0 && (
                <div>
                  <h3>Errors:</h3>
                  <ul>
                    {errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
              <button
                id="btnRegister"
                type="submit"
                className="btn btn-primary mt-5 col-12"
              >
                Register
              </button>
              <Link
                to="/signin"
                type="button"
                className="btn btn-secondary col-12 mt-2"
              >
                Sign In
              </Link>
            </form>

            <div className="col-12 d-flex justify-content-center align-items-center mb-3">
              <Link to="/" href="" className="text-center col-12 mt-5">
                Go back to home
              </Link>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-body d-flex justify-content-between mx-4 pb-3">
          <ul className="list-inline mb-0 p-0">
            <li className="list-inline-item">
              <a href="./dashboard/extra/privacy-policy.html">Privacy Policy</a>
            </li>
            <li className="list-inline-item">
              <a href="./dashboard/extra/terms-of-service.html">Terms of Use</a>
            </li>
          </ul>
          <div className="right-panel">
            ©<script>document.write(new Date().getFullYear())</script> Bridging
            Hope. All Rights Reserved.
          </div>
        </div>
      </footer>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Registration Successful</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Your account has been created successfully. You can now sign
                  in.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCloseModal}
                >
                  Go to Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Register;
