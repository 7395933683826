import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import profilePic1 from "../../ExampleProfile1.jpg";
import profilePic2 from "../../ExampleProfile2.jpg";

{
  /*
  Filename:    ManageVolunteers.js
  Description: This component allows an organization admin to view, add, edit, and remove volunteers from their organization.
*/
}

const ManageVolunteers = () => {
  const navigate = useNavigate();
  const [volunteers, setVolunteers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");

  //Gets the organizations
  useEffect(() => {
    const fetchOrganizations = async () => {
      // fetch organizations from the database
      const sessionID = Cookies.get("sessionID");

      //Checks validity
      if (!sessionID) {
        alert("Session expired. Please sign in again.");
        navigate("/SignIn");
        return;
      }

      try {
        //Get the organization names
        const response = await axios.get(
          "https://bridginghope.life/api/organization",
          {
            headers: {
              Authorization: `Bearer ${sessionID}`,
            },
            withCredentials: true,
          }
        );
        const data = await response.data;

        //Makes sure the data is in the correct format so it doesn't crash the site
        if (data?.OrgNames && Array.isArray(data.OrgNames)) {
          const formattedOrganizations = data.OrgNames.map((org, index) => ({
            id: index,
            name: org.name,
          }));

          //Sets the organizations
          setOrganizations(formattedOrganizations);
        } else {
          console.error("Error fetching organizations: ", data);
        }
      } catch (error) {
        console.error("Error fetching organizations: ", error);
      }
    };

    fetchOrganizations();
  }, []);

  //Gets the volunteers
  useEffect(() => {
    const fetchVolunteers = async () => {
      // fetch volunteers from the database
      const sessionID = Cookies.get("sessionID");
      const orgName = selectedOrg;

      //Checks validity
      if (!sessionID) {
        alert("Session expired. Please sign in again.");
        navigate("/SignIn");
        return;
      }

      //Gets the volunteers
      try {
        const response = await axios.get(
          "https://bridginghope.life/api/volunteer",
          {
            params: { orgName },
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionID}`,
            },
          }
        );
        const data = await response.data;

        //Makes sure the data is in the correct format so it doesn't crash the site
        if (data && Array.isArray(data.Volunteers)) {
          setVolunteers(data.Volunteers);
        } else {
          console.error("Invalid format: ", data);
          setVolunteers([]);
        }
      } catch (error) {
        console.error("Error fetching volunteers: ", error);
        setVolunteers([]);
      }
    };

    if (selectedOrg) {
      fetchVolunteers();
    }
  }, [selectedOrg, navigate]);

  return (
    <React.Fragment>
      <div className="card col-12 col-md-10 offset-0 offset-md-1 mt-3">
        {" "}
        {/* this card will have different widths depending on the resolution of the device */}
        <div className="card-body">
          <h2 className="mb-4 fs-3">Manage volunteers</h2>
          <select
            className="form-select w-auto mb-4"
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
          >
            <option value="">Select Organization</option>
            {organizations.map((org) => (
              <option key={org.name} value={org.name}>
                {org.name}
              </option>
            ))}
          </select>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Picture</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {volunteers.map((volunteer, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={volunteer.profilePic}
                      alt="profile picture"
                      style={{
                        width: "64px",
                        height: "64px",
                        "object-fit": "cover",
                      }}
                      class="rounded-circle"
                    />
                  </td>
                  <td>{volunteer.firstName}</td>
                  <td>{volunteer.lastName}</td>
                  <td>
                    <button className="btn btn-primary">Edit</button>
                    <button className="btn btn-danger ms-4">Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageVolunteers;
