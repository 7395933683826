import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import exampleLogo from "./../ExampleLogo.png";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";

/*
  Filename:    CreateOrganization.js
  Description: This component is used by the Bridging Hope Admin to create a new organization.
*/

// data sent to the post.api/organization endpoint should be sent in the following format:

function CreateOrganization() {
  //const url = 'https://bridginghope.life/api/organization';
  const url = "https://bridginghope.life/api/organization"; //uncomment for local testing
  const navigate = useNavigate();

  const [organizationInfo, setOrganizationInfo] = useState({
    orgID: "",
    name: "",
    description: "",
    logo: "",
    orgType: "",
  });

  const [phoneInfo, setPhoneInfo] = useState({
    phoneID: "",
    countryCode: "",
    areaCode: "",
    telPrefix: "",
    lineNum: "",
    isActive: true,
    isSafe: true,
    phoneType: "",
  });

  const [addressInfo, setAddressInfo] = useState({
    addressID: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    addressType: "",
  });

  const [userInfo, setUserInfo] = useState({
    userID: "",
    email: "",
    userName: "",
    password: "",
  });

  const [confirmPassword, setConfirmPassword] = useState({
    confirmPassword: "",
  });

  const services = [
    { serviceID: 0, serviceType: "Food" },
    { serviceID: 1, serviceType: "Debt Payment" },
    { serviceID: 2, serviceType: "Medical" },
  ];

  // const [serviceInfo, setServiceInfo] = useState([]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (event) => {
    const { dataset, name, value, checked } = event.target;

    if (dataset.type === "organization") {
      setOrganizationInfo({
        ...organizationInfo,
        [name]: value,
      });
    }
    if (dataset.type === "phone") {
      if (name === "phoneNumber") {
        let countryCode = phoneInfo.countryCode || "1";
        let areaCode = value.slice(0, 3);
        let telPrefix = value.slice(3, 6);
        let lineNum = value.slice(6, 10);
        setPhoneInfo({
          ...phoneInfo,
          countryCode: countryCode,
          areaCode: areaCode,
          telPrefix: telPrefix,
          lineNum: lineNum,
        });
      } else {
        setPhoneInfo({
          ...phoneInfo,
          [name]: value,
        });
      }
    }
    if (dataset.type === "address") {
      setAddressInfo({
        ...addressInfo,
        [name]: value,
      });
    }
    if (dataset.type === "user") {
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    }
    if (dataset.type === "confirmPassword") {
      setConfirmPassword({
        ...confirmPassword,
        [name]: value,
      });
    }
    // if (dataset.type === "service") {
    //   setServiceInfo((prev) => {
    //     if (checked) {
    //       // Add the service if checked
    //       return [...prev, { serviceID: dataset.serviceid, [name]: value }];
    //     } else {
    //       // Remove the service if unchecked
    //       return prev.filter((s) => s.serviceID !== dataset.serviceid);
    //     }
    //   });
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let params = {
      organization: organizationInfo,
      phone: phoneInfo,
      address: addressInfo,
      user: userInfo,
      // services: serviceInfo,
    };

    //Make the API call
    try {
      //Get the cookie
      const sessionID = Cookies.get("sessionID");

      if (!sessionID) {
        alert("Session expired. Please log in again.");
        navigate("/SignIn");
        return;
      }

      const response = await axios.post(url, params, {
        withCredentials: true, //Ensures cookies are sent
      });
      console.log("Organization created: ", response);

      setShowSuccessModal(true); // Show success modal
    } catch (error) {
      console.error("Error creating organization: ", error);

      //If the response exists
      if (error.response) {
        console.error("Response status: ", error.response.status);
        console.error("Response data: ", error.response.data);
        const errorMessage = error.response?.data?.message; // || 'Error creating organization';
        alert(`Registration Failed: ` + errorMessage);

        //Database Error
      } else if (error.request) {
        console.error("Request error: ", error.request);
        alert("No response from server");

        //Other errors
      } else {
        console.error("Unexpected Error:" + error.message);
        alert("Unexpected Error: " + error.message);
      }
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate("/dashboard"); // Redirect to dashboard
  };

  return (
    <React.Fragment>
      <h1 className="mt-3 ms-5">Create Organization</h1>
      <div className="card col-12 col-md-10 offset-0 offset-md-1 mt-3">
        {" "}
        {/* this card will have different widths depending on the resolution of the device */}
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <h2 className="fs-4 mb-1">Basic Info</h2>
            <div className="row">
              <div className="col">
                <label htmlFor="orgName" className="form-label mt-2">
                  Organization name
                </label>
                <input
                  id="txtOrgName"
                  type="text"
                  placeholder="Organization XYZ"
                  className="form-control"
                  data-type="organization"
                  name="name"
                  value={organizationInfo.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="orgType" className="form-label mt-2">
                  Organization type
                </label>
                <select
                  id="txtOrgType"
                  className="form-select"
                  data-type="organization"
                  name="orgType"
                  value={organizationInfo.orgType}
                  onChange={handleChange}
                >
                  <option
                    value="default"
                    className="text-secondary"
                    aria-label="select ID Type"
                  >
                    select
                  </option>
                  <option value="Religious">Religious Organization</option>
                  <option value="Government">Government</option>
                  <option value="Nonprofit">Nonprofit</option>
                </select>
              </div>
            </div>
            <div className="row g-2 align-items-end">
              <div className="col-auto">
                <label htmlFor="countryCode" className="form-label mt-2">
                  Country code
                </label>
                <select
                  id="countryCode"
                  className="form-select w-auto"
                  data-type="phone"
                  name="countryCode"
                  value={phoneInfo.countryCode}
                  onChange={handleChange}
                >
                  <option value="1" className="text-secondary">
                    +1
                  </option>
                </select>
              </div>
              <div className="col">
                <label htmlFor="phone" className="form-label mt-2">
                  Phone
                </label>
                <input
                  id="phone"
                  type="tel"
                  placeholder="1234567890"
                  className="form-control"
                  data-type="phone"
                  name="phoneNumber"
                  value={
                    phoneInfo.areaCode + phoneInfo.telPrefix + phoneInfo.lineNum
                  }
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="phoneType" className="form-label mt-2">
                  Phone type
                </label>
                <select
                  id="phoneType"
                  className="form-select"
                  aria-label="select phone type"
                  data-type="phone"
                  name="phoneType"
                  value={phoneInfo.phoneType}
                  onChange={handleChange}
                >
                  <option value="default" className="text-secondary">
                    select
                  </option>
                  <option value="Personal">Personal</option>
                  <option value="Work">Work</option>
                  <option value="Business">Business</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <label htmlFor="orgDescription" className="form-label mt-2">
                  Description
                </label>
                <textarea
                  id="orgDescription"
                  className="form-control"
                  placeholder="Description of the organization"
                  rows="3"
                  data-type="organization"
                  name="description"
                  value={organizationInfo.description}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>

            <h2 className="text-center mt-4">Current logo</h2>
            <div className="d-flex justify-content-center">
              <img
                src={exampleLogo}
                alt="Example Logo"
                width="128"
                height="128"
                className="rounded"
              />
            </div>
            <label htmlFor="orgLogo" className="form-label mt-2">
              Upload logo
            </label>
            <input
              id="orgLogo"
              className="form-control"
              type="file"
              data-type="organization"
              name="logo"
              value={organizationInfo.logo}
              onChange={handleChange}
            />

            <h2 className="fs-4 mt-4">Address</h2>
            <label htmlFor="addressLine1" className="form-label mt-2">
              Street address 1
            </label>
            <input
              id="addressLine1"
              type="text"
              placeholder="123 Cherry Lane"
              className="form-control"
              data-type="address"
              name="addressLine1"
              value={addressInfo.addressLine1}
              onChange={handleChange}
              required
            />
            <label htmlFor="addressLine2" className="form-label mt-2">
              Street address 2
            </label>
            <input
              id="addressLine2"
              type="text"
              placeholder="Apartment 001"
              className="form-control"
              data-type="address"
              name="addressLine2"
              value={addressInfo.addressLine2}
              onChange={handleChange}
            />
            <div className="row">
              <div className="col">
                <label htmlFor="city" className="form-label mt-2">
                  City
                </label>
                <input
                  id="city"
                  type="text"
                  placeholder="City"
                  className="form-control"
                  data-type="address"
                  name="city"
                  value={addressInfo.city}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <label htmlFor="state" className="form-label mt-2">
                  State
                </label>
                <select
                  id="state"
                  className="form-select"
                  aria-label="select state"
                  data-type="address"
                  name="state"
                  value={addressInfo.state}
                  onChange={handleChange}
                >
                  <option value="default" className="text-secondary">
                    select
                  </option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              <div className="col">
                <label htmlFor="zip" className="form-label mt-2">
                  Zip
                </label>
                <input
                  id="zip"
                  type="text"
                  placeholder="Zip"
                  className="form-control"
                  data-type="address"
                  name="zip"
                  value={addressInfo.zip}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="addressType" className="form-label mt-2">
                  Address type
                </label>
                <select
                  id="addressType"
                  className="form-select"
                  aria-label="select address type"
                  data-type="address"
                  name="addressType"
                  value={addressInfo.addressType}
                  onChange={handleChange}
                >
                  <option value="default" className="text-secondary">
                    select
                  </option>
                  <option value="Home">Home</option>
                  <option value="Shelter">Shelter</option>
                  <option value="Business">Business</option>
                </select>
              </div>
            </div>

            {/*<h2 className="fs-4 mt-4 mb-2">Services</h2>*/}
            {/* {services.map((service) => (
              <div className="form-check" key={service.serviceID}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"txt" + service.serviceType.replaceAll(" ", "")}
                  data-type="service"
                  name="serviceType"
                  value={service.serviceType}
                  data-serviceid={service.serviceID}
                  checked={serviceInfo.some(
                    (s) => Number(s.serviceID) === Number(service.serviceID)
                  )}
                  onChange={handleChange}
                />
                <label
                  htmlFor={"txt" + service.serviceType.replaceAll(" ", "")}
                  className="form-check-label"
                >
                  {service.serviceType}
                </label>
              </div>
            ))} */}

            <h2 className="fs-4 mt-4">Admin User</h2>
            <div className="row">
              <div className="row col-md-5 ms-2">
                <label htmlFor="email" className="form-label mt-2">
                  Email
                </label>
                <input
                  id="userEmail"
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  data-type="user"
                  name="email"
                  value={userInfo.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="row col-md-5 ms-2">
                <label htmlFor="userName" className="form-label mt-2">
                  Username
                </label>
                <input
                  id="userName"
                  type="text"
                  placeholder="Username"
                  className="form-control"
                  data-type="user"
                  name="userName"
                  value={userInfo.userName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="row col-md-5 ms-2">
                <label htmlFor="password" className="form-label mt-2">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  data-type="user"
                  name="password"
                  value={userInfo.password}
                  onChange={handleChange}
                />
              </div>
              <div className="row col-md-5 ms-2">
                <label htmlFor="txtConfirmPassword" className="form-label mt-2">
                  Confirm Password
                </label>
                <input
                  id="txtConfirmPassword"
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  data-type="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword.confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <button type="submit" className="btn btn-primary">
                Create Organization!
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Success Modal */}
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Organization Created!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your organization has been successfully created!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Go to Dashboard
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CreateOrganization;
