// Filename:    SearchClient.js
// Description: This component renders the components in /searchResults.

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import SearchForm from "./searchResults/SearchForm";
import SearchInstructions from "./searchResults/SearchInstructions";
import ClientList from "./searchResults/ClientList";
import ClientView from "./searchResults/ClientView";
import HouseholdAidHistory from "./searchResults/HouseholdAidHistory";

function SearchClient() {
  const [instructionsIsOpen, setInstructionsIsOpen] = React.useState(true);
  const [clientInfoIsOpen, setClientInfoIsOpen] = React.useState(false);
  const [selectedClientID, setSelectedClientID] = React.useState(null);
  const [clients, setClients] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState(null);

  const navigate = useNavigate();

  let left;
  let right;

  async function handleSearchClient(searchParams) {
    //const url = 'https://bridginghope.life/api/clientSearch';
    //const url = process.env.REACT_APP_URL + '/api/clientSearch?sessionID=${sessionID}';
    const url = "https://bridginghope.life/api/clientSearch";

    setInstructionsIsOpen(false);

    try {
      //Get the cookie
      const sessionID = Cookies.get("sessionID");

      if (!sessionID) {
        alert("Session expired. Please log in again.");
        navigate("/SignIn");
        return;
      }

      const response = await axios.get(url, {
        params: searchParams,
        headers: {
          Authorization: `Bearer ${sessionID}`,
        },
        withCredentials: true, //Ensures cookies are sent
      });

      setClients(response.data.clients);
    } catch (error) {
      console.error("Error fetching clients", error);
    }
  }

  function handleSelectClient(clientID) {
    setSelectedClientID(clientID);

    //function handleSelectClient(client) {
    //setSelectedClient(client);

    setClientInfoIsOpen(true);
  }
  function handleBackToSearch() {
    setSelectedClientID(null);
    setClientInfoIsOpen(false);
    setSelectedClient(null);
  }

  if (instructionsIsOpen) {
    left = <SearchForm onSearch={handleSearchClient} />;
    right = <SearchInstructions />;
  } else if (clientInfoIsOpen) {
    left = (
      <ClientView clientID={selectedClientID} onBack={handleBackToSearch} />
    );
    right = <HouseholdAidHistory />;
    //left = <ClientView client={selectedClient} onBack={handleBackToSearch} />
    //right = <HouseholdAidHistory client={selectedClient}/>;
  } else {
    left = <SearchForm onSearch={handleSearchClient} />;
    right = <ClientList clients={clients} onSelect={handleSelectClient} />;
  }

  return (
    <React.Fragment>
      <div className="card col-lg-10 col-md-10 col-sm-12 offset-lg-1 offset-md-1 offset-sm-0 mt-3">
        {" "}
        {/* this card will have different widths depending on the resolution of the device */}
        <div className="card-body p-0">
          <div className="row">
            {" "}
            {/* For large screens, this row has two columns: search fields and result. Results are below on smaller screens. */}
            <div className="col-lg-6 col-12">
              {/* Left column of screen */}
              {left}
            </div>
            <div className="col-lg-6 col-12 bg-light rounded-end">
              {/* Right column of screen */}
              {right}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchClient;
