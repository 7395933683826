import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

function OrganizationList({ onSelect }) {
  const [organizations, setOrganizations] = useState([]);

  // useEffect(() => {
  // 	// The following fetch call hits your backend endpoint at /api/organization.
  // 	// Your backend code first checks for a valid session and then runs this query:
  // 	//   SELECT name FROM tblOrganization
  // 	// The result is returned as JSON in the format: { "OrgNames": [ { name: "Organization XYZ" }, ... ] }
  // 	fetch("https://bridginghope.life/api/organization", { credentials: "include" })
  // 		.then((res) => {
  // 			if (!res.ok) {
  // 				throw new Error("Failed to fetch organizations");
  // 			}
  // 			return res.json();
  // 		})
  // 		.then((data) => {
  // 			// Here we simply take the returned OrgNames array and set it to our state.
  // 			setOrganizations(data.OrgNames);
  // 		})
  // 		.catch((error) => {
  // 			console.error("Error fetching organizations:", error);
  // 		});
  // }, []);
  const navigate = useNavigate();
  const url = "https://bridginghope.life/api/organization";
  useEffect(() => {
    async function getOrgNames() {
      try {
        const sessionID = Cookies.get("sessionID");

        if (!sessionID) {
          alert("Session expired. Please log in again.");
          navigate("/SignIn");
          return;
        }

        const response = await axios.get(url, {
          withCredentials: true, //Ensures cookies are sent
        });

        response.data.OrgNames.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setOrganizations(response.data.OrgNames);
      } catch (error) {
        console.error("Error fetching organizations", error);
      }
    }
    getOrgNames();
  }, []);

  return (
    <React.Fragment>
      <ul className="list-group">
        {organizations.map((org, index) => (
          <li key={index} value={index} className="list-group-item">
            <div className="row">
              {/* <div className="col-2">
								<img
									src={org.logo || "defaultOrgLogo.jpg"}
									alt="organization logo"
									style={{ width: "64px", height: "64px", objectFit: "cover" }}
									className="rounded-circle"
								/>
							</div> */}
              <div className="col-10 row d-flex align-items-center">
                <p className="col-8">{org.name}</p>
                <button
                  type="button"
                  className="col-4 btn btn-primary"
                  onClick={() => onSelect(org.id)}
                >
                  Select
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
}

export default OrganizationList;
