// Filename:    ClientView.js
// Description: This component renders the details of a specific client.

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { v4 as uuidv4, validate } from "uuid";

//import defaultprofilePic from "./images/defaultProfilePic.avif";
import profilePic1 from "./../../ExampleProfile1.jpg";
import profilePic2 from "./../../ExampleProfile2.jpg";

{
  /*
  Filename:    ClientView.js
  Description: This component renders the details of a specific client.
*/
}

function ClientView({ onBack, clientID }) {
  //Intially sets the client data
  const [client, setClient] = useState({
    clientID: clientID,
    firstName: "",
    middleName: "",
    lastName: "",
    DOB: "",
    photo: "",
    userID: "",
    addressInfo: {
      addressID: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      zip: "",
      addressTypeInfo: {
        addressTypeID: "",
        name: "",
        description: "",
      },
    },
    phoneInfo: {
      phoneID: "",
      countryCode: "",
      areaCode: "",
      telPrefix: "",
      lineNum: "",
      isActive: "",
      isSafe: "",
      phoneTypeInfo: {
        phoneTypeID: "",
        name: "",
        description: "",
      },
    },
  });

  //Initially Set the Data
  const [visitInfo, setVisitInfo] = useState({
    visitID: null,
    date: "",
    notes: "",
    reason: "",
    outcome: "",
    followUp: "",
    clientID: clientID,
    volunteerID: "",
  });

  const [serviceInfo, setServiceInfo] = useState({
    serviceID: "",
    serviceType: "Food",
    orgName: "Test1",
  });

  //Update based on error or success
  const [errors, setErrors] = useState({ message: "" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setVisitInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleServiceChange = (e) => {
    let { name, value } = e.target;
    setServiceInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogVisit = async (e) => {
    e.preventDefault();

    //local url
    const url = "https://bridginghope.life/api/client/logVisit";

    //Get the cookie
    const sessionID = Cookies.get("sessionID");
    const userID = Cookies.get("userID");

    if (!sessionID) {
      alert("Session expired. Please log in again.");
      navigate("/SignIn");
      return;
    }

    try {
      //Construct the correct payload
      const requestData = {
        visit: {
          visitID: visitInfo.visitID || uuidv4(),
          reason: visitInfo.reason,
          notes: visitInfo.notes,
          outcome: visitInfo.outcome,
          followUp: visitInfo.followUp,
          date: visitInfo.date,
          clientID: visitInfo.clientID,
          volunteerID: userID,
        },
        service: {
          serviceID: serviceInfo.serviceID || uuidv4(),
          serviceType: serviceInfo.serviceType,
          orgName: serviceInfo.orgName,
        },
      };

      //Post Request
      const response = await axios.post(url, requestData, {
        headers: { Authorization: `Bearer ${sessionID}` },
        withCredentials: true,
      });

      console.log("Visit Logged Successfully: ", response);
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error Logging Visit: ", error);

      if (error.response) {
        const errorMessage =
          error.response?.data?.message || "Error Logging Visit";
        setErrors({ message: errorMessage });
        alert("Logging Failed: " + errorMessage);
      } else if (error.request) {
        alert("Logging Failed: " + error.request);
      } else {
        alert("Logging Failed Unexpected: " + error.message);
      }
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate("/dashboard");
  };

  let profilePic;

  if (client.photo == null) {
    profilePic = "./images/defaultProfilePic.avif";
  } else {
    profilePic = client.photo;
  }

  return (
    <React.Fragment>
      {/* Profile picture */}
      <div className="d-flex justify-content-center py-1 bg-info">
        <img
          src={profilePic}
          alt="profile picture"
          style={{ width: "128px", height: "128px", "object-fit": "cover" }}
          className="rounded-circle"
        />
      </div>

      {/* Client information */}
      {/* Currently, only the name and date of birth are displayed dynamically. The other fields need some work. */}
      <div className="row">
        <div className="col-5">
          <div className="row">
            <h2 className="col-6 fs-6">Name</h2>
            <p className="col-6">
              {client.firstName} {client.lastName}
            </p>

            <h2 className="col-6 fs-6">Phone</h2>
            <p className="col-6">1234567890</p>

            <h2 className="col-6 fs-6">DOB</h2>
            <p className="col-6">{client.DOB}</p>
          </div>
        </div>
        <div className="col-7">
          <div className="row">
            <h2 className="col-6 fs-6">Street address 1</h2>
            <p className="col-6">123 Cherry Lane</p>

            <h2 className="col-6 fs-6">Street address 2</h2>
            <p className="col-6">Apt 100</p>

            <h2 className="col-6 fs-6">City</h2>
            <p className="col-6">Cookeville</p>

            <h2 className="col-6 fs-6">State</h2>
            <p className="col-6">Tennessee</p>

            <h2 className="col-6 fs-6">Zip</h2>
            <p className="col-6">38500</p>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="d-flex justify-content-between py-1 bg-light">
        <button type="button" className="btn btn-warning mx-2" onClick={onBack}>
          Back
        </button>
        <button
          type="button"
          className="btn btn-primary mx-2"
          data-bs-toggle="modal"
          data-bs-target="#editHouseholdBackdrop"
        >
          Edit household
        </button>
        <button
          type="button"
          className="btn btn-primary mx-2"
          data-bs-toggle="modal"
          data-bs-target="#editClientBackdrop"
        >
          Edit client
        </button>
        <button
          type="button"
          className="btn btn-success mx-2"
          data-bs-toggle="modal"
          data-bs-target="#logVisitBackdrop"
        >
          Log visit
        </button>
      </div>

      {/* Edit household modal */}
      <div
        className="modal fade"
        id="editHouseholdBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Edit household
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Picture</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src={profilePic1}
                        alt="profile picture"
                        style={{
                          width: "64px",
                          height: "64px",
                          "object-fit": "cover",
                        }}
                        className="rounded-circle"
                      />
                    </td>
                    <td>John</td>
                    <td>Doe</td>
                    <td>
                      <button type="button" className="btn btn-danger">
                        Delete
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src={profilePic2}
                        alt="profile picture"
                        style={{
                          width: "64px",
                          height: "64px",
                          "object-fit": "cover",
                        }}
                        className="rounded-circle"
                      />
                    </td>
                    <td>Jane</td>
                    <td>Doe</td>
                    <td>
                      <button type="button" className="btn btn-danger">
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="button" className="btn btn-secondary">
                + Add household member
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit client modal */}
      <div
        className="modal fade"
        id="editClientBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Edit client
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="txtName" className="form-label">
                      Name
                    </label>
                    <input
                      id="txtName"
                      type="text"
                      placeholder="John Doe"
                      className="form-control"
                    />
                    <label htmlFor="txtEmail" className="form-label mt-2">
                      Email
                    </label>
                    <input
                      id="txtEmail"
                      type="email"
                      placeholder="jdoe@email.com"
                      className="form-control"
                    />
                    <label htmlFor="txtPhone" className="form-label mt-2">
                      Phone
                    </label>
                    <input
                      id="txtPhone"
                      type="tel"
                      placeholder="(555)-456-7890"
                      className="form-control"
                    />
                    <label htmlFor="txtDOB" className="form-label mt-2">
                      Date of Birth
                    </label>
                    <input id="txtDOB" type="date" className="form-control" />
                  </div>
                  <div className="col-6">
                    <label htmlFor="txtStreetAddress1" className="form-label">
                      Street address 1
                    </label>
                    <input
                      id="txtStreetAddress1"
                      type="text"
                      placeholder="123 Main St."
                      className="form-control"
                    />
                    <label
                      htmlFor="txtStreetAddress2"
                      className="form-label mt-2"
                    >
                      Street address 2
                    </label>
                    <input
                      id="txtStreetAddress2"
                      type="text"
                      placeholder="Apt. 1"
                      className="form-control"
                    />
                    <label htmlFor="txtCity" className="form-label mt-2">
                      City
                    </label>
                    <input
                      id="txtCity"
                      type="text"
                      placeholder="Cookeville"
                      className="form-control"
                    />
                    <label htmlFor="txtState" className="form-label mt-2">
                      State
                    </label>
                    <select
                      id="txtState"
                      className="form-select"
                      aria-label="select state"
                      defaultValue="default"
                    >
                      <option value="default" className="text-secondary">
                        select
                      </option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                    <label htmlFor="txtZip" className="form-label mt-2">
                      Zip
                    </label>
                    <input
                      id="txtZip"
                      type="text"
                      placeholder="38501"
                      className="form-control"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-success">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Log visit modal */}
      <div
        className="modal fade"
        id="logVisitBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Log visit
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/*Date and Follow Up*/}
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="txtDate" className="form-label">
                    Date
                  </label>
                  <input
                    id="txtDate"
                    type="date"
                    name="date"
                    className="form-control"
                    value={visitInfo.date}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtFollowUp" className="form-label mt-2">
                    Follow-up
                  </label>
                  <input
                    id="txtFollowUp"
                    type="date"
                    name="followUp"
                    className="form-control"
                    rows="4"
                    value={visitInfo.followUp}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/*Notes*/}
              <div className="mt-2">
                <label htmlFor="txtNotes" className="form-label mt-2">
                  Notes
                </label>
                <textarea
                  id="txtNotes"
                  name="notes"
                  placeholder="Notes"
                  className="form-control"
                  rows="4"
                  value={visitInfo.notes}
                  onChange={handleChange}
                />
              </div>

              {/*Reason*/}
              <div className="mt-2">
                <label htmlFor="txtReason" className="form-label mt-2">
                  Reason for visit
                </label>
                <textarea
                  id="txtReason"
                  type="text"
                  name="reason"
                  placeholder="Reason for visit"
                  className="form-control"
                  value={visitInfo.reason}
                  onChange={handleChange}
                />
              </div>

              {/*Service Type and Outcome*/}
              <div className="row mt-2">
                <div className="col-md-6">
                  <label htmlFor="txtServiceType" className="form-label">
                    Service type
                  </label>
                  <select
                    id="txtServiceType"
                    name="serviceType"
                    className="form-select"
                    aria-label="select service type"
                    value={serviceInfo.serviceType}
                    onChange={handleServiceChange}
                  >
                    <option value="Food">Food</option>
                    <option value="Debt Payment">Debt payment</option>
                    <option value="Medical">Medical</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="txtOutcome" className="form-label mt-2">
                    Outcome
                  </label>
                  <input
                    id="txtOutcome"
                    type="text"
                    name="outcome"
                    placeholder="Outcome"
                    className="form-control"
                    value={visitInfo.outcome}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleLogVisit}
              >
                Log visit!
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Success modal */}
      <Modal show={showSuccessModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your visit has been logged successfully!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ClientView;
